'use client'
import { useGetHomePageSalesQuery } from '@/app/globalRedux/api/homePageSlice'
import { useTranslation } from '@/app/i18n/client'
import Image from 'next/image'
import Link from 'next/link'
import { useParams } from 'next/navigation'
import CustomErrorBoundary from '../ErrorBoundary'
import HomeSalesListCard from './SalesOffer/HomeSalesListCard'
import HomeSalesOfferSkeleton from './Skeletons/HomeSalesOfferSkeleton'
import { SalesOffersResponse } from '@/app/types/homeSalesOfferResponseType'
const SalesOffers = () => {
  const params = useParams()
  const { t } = useTranslation()

  const {
    data: salesOfferList,
    isLoading,
    isError,
  } = useGetHomePageSalesQuery<{
    data: SalesOffersResponse
    isLoading: boolean
    isError: boolean
  }>({})
  return (
    <div className="w-[517px] flex flex-col mobile-width">
      <div className="w-full flex justify-between items-center">
        <div className="text-[20px] text-[#222222] py-4 font-ns_bold mobile:py-2">
          {t('Sales Offer')}
        </div>
        {!isLoading && !isError && salesOfferList?.sales_offers?.length > 0 && (
          <Link href={`/${params?.lng}/sales-listing`}>
            <div className="text-sm flex items-center text-sickly_green">
              <span className="cursor-pointer text-[14px] font-ns_bold select-none ">
                {t('View All')}
              </span>
            </div>
          </Link>
        )}
      </div>
      <div className="flex flex-col gap-[6px] mobile:gap-[10px]">
        {!isLoading && !isError ? (
          salesOfferList?.sales_offers?.length > 0 ? (
            salesOfferList?.sales_offers?.map((item: any, index: number) => (
              <CustomErrorBoundary key={`sales_card_${item.offer.id}_${index}`}>
                <Link
                  href={`/${params?.lng}/sales_offers/${item.offer.id}`}
                  prefetch={false}
                >
                  <HomeSalesListCard cardData={item.offer} />
                </Link>
              </CustomErrorBoundary>
            ))
          ) : (
            <div className=" min-h-[964px] w-[100%] shadow-[0_0_6px_0_rgba(0,0,0,0.16)] rounded-[3px] items-center justify-center flex flex-col">
              <Image
                height={500}
                width={400}
                alt="No Sales Offer List"
                src="/assets/Icons/noImage/noSalesOffer.svg"
              />
              <p className="text-[20px] not-italic leading-[normal] tracking-[normal] text-center text-[#575757] p-5">
                {t(
                  'Currently there are no sales offer available at this moment'
                )}
              </p>
            </div>
          )
        ) : (
          Array.from({ length: 5 }, (_, index) => (
            <HomeSalesOfferSkeleton key={index + 'home_sales_offer_skeleton'} />
          ))
        )}
      </div>
      <span className="block h-[1px] w-full bg-gray_btn my-3"></span>
      <div className="flex justify-center items-center mb-4 mx-6 mobile:mx-[0px] mobile:mb-0">
        <div className="text-sm flex w-full items-center justify-center text-sickly_green mobile:bg-secondary mobile:text-white mobile:py-[10px] mobile:rounded">
          {!isLoading &&
            !isError &&
            salesOfferList?.sales_offers?.length > 0 && (
              <Link href={`/${params?.lng}/sales-listing`}>
                <span className="cursor-pointer select-none font-ns_bold text-base mobile-width mobile:text-center">
                  {t('View All')}
                </span>
              </Link>
            )}
        </div>
      </div>
    </div>
  )
}

export default SalesOffers
