'use client'
import { useGetAdsQuery } from '@/app/globalRedux/api/adsSlice'
import { RootState } from '@/app/globalRedux/store'
import {
  handleAdIntersection,
  useIntersectionObserver,
} from '@/app/hooks/useIntersectionObserver'
import { AdBannerScheduleType } from '@/app/types/adType'
import { getAdsIndex, handleAdClick } from '@/app/utils/AdsUtility'
import Image from 'next/image'
import { useParams } from 'next/navigation'
import { useEffect, useRef, useState } from 'react'
import { useSelector } from 'react-redux'
import AdCarousel from '../AdCarousel'
import { useNavigation } from '@/app/utils/navigation'

const LeftAdsPanel = () => {
  const params = useParams<{ lng: string }>()
  const { redirectToAdvertise } = useNavigation()
  const { data: adsHomePage } = useGetAdsQuery<{
    data: AdBannerScheduleType[]
    isLoading: boolean
    isError: boolean
    isFetching: boolean
  }>({ page: 'Home Page', lang: params.lng })

  const localeLangauge = useSelector(
    (state: RootState) => state.user.localeLangauge
  )
  const adPositionThreeRef = useRef<HTMLDivElement>(null)
  const adPositionFourRef = useRef<HTMLDivElement>(null)
  const adPositionFiveRef = useRef<HTMLDivElement>(null)
  const [adForPositionThree, setAdForPositionThree] =
    useState<AdBannerScheduleType>()
  const [adForPositionFour, setAdForPositionFour] =
    useState<AdBannerScheduleType>()
  const [adForPositionFive, setAdForPositionFive] =
    useState<AdBannerScheduleType>()

  const observeElement = useIntersectionObserver(handleAdIntersection)

  useEffect(() => {
    if (adsHomePage) {
      setAdForPositionThree(
        adsHomePage[getAdsIndex(adsHomePage, 'Ad Position 3')]
      )
      setAdForPositionFour(
        adsHomePage[getAdsIndex(adsHomePage, 'Ad Position 4')]
      )
      setAdForPositionFive(
        adsHomePage[getAdsIndex(adsHomePage, 'Ad Position 5')]
      )
    }
  }, [adsHomePage])

  useEffect(() => {
    if (adPositionThreeRef.current) {
      observeElement(adPositionThreeRef.current)
    }
    if (adPositionFourRef.current) {
      observeElement(adPositionFourRef.current)
    }
    if (adPositionFiveRef.current) {
      observeElement(adPositionFiveRef.current)
    }
  }, [adForPositionThree, adForPositionFour, adForPositionFive, observeElement])

  return (
    <div className="mobile:hidden relative content">
      <div className="flex flex-col my-3 gap-5 mobile:gap-[10px]">
        {/* Ad Position 3 */}
        <AdCarousel
          adList={adsHomePage}
          adPosition="Ad Position 3"
          dimensions={{ width: 300, height: 450 }}
          fallbackImagePath="/assets/image/FructidorAds/{lang}/HomePage/Ad3 300x450.png"
        />
        {/* Ad Position 4 */}
        <div
          ref={adPositionFourRef}
          data-ad-position="Ad Position 4"
          data-ad-id={adForPositionFour?.ad_banner?.id}
          data-ad-sid={adForPositionFour?.id}
          data-ad-page={adForPositionFour?.page?.name}
          data-ad-company-id={adForPositionFour?.ad_banner?.company?.id}
          data-ad-lang={params?.lng}
          className="ad_banner_gtm flex w-[300px] h-[300px] cursor-pointer rounded-[3px] overflow-hidden mobile:h-[auto]"
        >
          {adForPositionFour ? (
            <Image
              loading="lazy"
              width={300}
              height={300}
              src={adForPositionFour?.ad_banner?.image}
              alt=""
              id="ad_click"
              className="object-cover w-[300px] h-[300px]"
              onClick={() => handleAdClick(adForPositionFour, params?.lng)}
            />
          ) : (
            <Image
              priority
              width={300}
              height={300}
              className="object-cover"
              src={`/assets/image/FructidorAds/${localeLangauge}/HomePage/Ad4 300x300.png`}
              alt=""
              onClick={redirectToAdvertise}
            />
          )}
        </div>
        {/* Ad Position 5 */}
        <div
          ref={adPositionFiveRef}
          data-ad-position="Ad Position 5"
          data-ad-id={adForPositionFive?.ad_banner?.id}
          data-ad-sid={adForPositionFive?.id}
          data-ad-page={adForPositionFive?.page?.name}
          data-ad-company-id={adForPositionFive?.ad_banner?.company?.id}
          data-ad-lang={params?.lng}
          className="ad_banner_gtm flex w-[300px] h-[150px] cursor-pointer rounded-[3px] overflow-hidden mobile:h-[auto]"
        >
          {adForPositionFive ? (
            <Image
              priority
              width={300}
              height={150}
              src={adForPositionFive?.ad_banner?.image}
              alt=""
              id="ad_click"
              className="object-cover w-[300px] h-[150px]"
              onClick={() => handleAdClick(adForPositionFive, params?.lng)}
            />
          ) : (
            <Image
              priority
              width={300}
              height={150}
              className="object-cover"
              src={`/assets/image/FructidorAds/${localeLangauge}/HomePage/Ad5 300x150.png`}
              alt=""
              onClick={redirectToAdvertise}
            />
          )}
        </div>
      </div>
    </div>
  )
}

export default LeftAdsPanel
