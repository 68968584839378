'use client'

import { useTranslation } from '@/app/i18n/client'
import { LocalType } from '@/app/types/constantDataType'
import { validateEmail } from '@/app/utils/validationUtils'
import { Button } from '@/components/ui/button'
import Image from 'next/image'
import { useParams, useRouter } from 'next/navigation'
import { useState } from 'react'
const NewsLetter = () => {
  const [email, setEmail] = useState('')
  const { t } = useTranslation()
  const router = useRouter()
  const { lng } = useParams<{ lng: LocalType }>()
  const redirectToNewsLetter = () => {
    if (validateEmail(email)) {
      router.push(
        `/${lng}/newsletter-subscription?email=${encodeURIComponent(email)}`
      )
    }
  }

  return (
    <div className="mobile:w-full">
      <div className="h-[320px] bg-[#fff5de] flex justify-center items-center flex-col mobile:py-[40px] mobile:text-center">
        <div className="text-[26px] font-ns_bold mb-[21px] mobile:text-[22px]">
          {t('JOIN OUR NEWSLETTER FOR FREE')}
        </div>
        <div className="text-center mb-[41px] mobile:text-[14px]">
          {t(
            'Register now to receive latest News and Opportunities of fruit-vegetables market,'
          )}{' '}
          <br />
          {t('Don’t worry, we provide an email preference center')}
        </div>
        <form
          className="space-y-6"
          onSubmit={(e) => {
            e.preventDefault()
            redirectToNewsLetter()
          }}
        >
          <input
            type="submit"
            style={{ position: 'absolute', left: '-9999px' }}
            tabIndex={-1}
          />
          <div className="w-[527px] h-[50px] bg-[white] flex rounded-[5px] shadow-[0_0_27px_0_rgba(0,0,0,0.15)] mobile:w-[350px]">
            <input
              type="email"
              placeholder={t('Enter your email address')}
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              className="inputField1 w-full pl-[24px] text-sm rounded-[5px] outline-none"
            />
            <div className="flex items-center newsletter-input">
              <Button
                variant="outline"
                className="border-none flex hover:bg-transparent bg-transparent"
                disabled={!validateEmail(email)}
                onClick={() => {}}
              >
                <Image
                  loading="lazy"
                  width={23.1}
                  height={24}
                  src="/assets/Icons/sendButton.svg"
                  alt=""
                  className="mr-[17px] "
                />
              </Button>
            </div>
          </div>
        </form>
      </div>
    </div>
  )
}

export default NewsLetter
