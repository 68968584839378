'use client'
import useScreenSize from '@/app/hooks/useScreenSize'
import { useTranslation } from '@/app/i18n/client'
import { NewsDetail } from '@/app/types/newsType'
import ShareSocials from '@/components/ui/ShareSocials'
import { decodeHTMLEntities } from '@/lib/utils'
import Image from 'next/image'
import { useParams } from 'next/navigation'
import React from 'react'
import sanitizeHtml from 'sanitize-html'
import CountryTag from '../../../../components/CountryTag'

interface NewsCardProps {
  newsData: NewsDetail
  isHomePage?: boolean
}

const NewsResultCardContent: React.FC<NewsCardProps> = ({
  newsData,
  isHomePage,
}) => {
  const { t } = useTranslation()
  const params = useParams()

  const screenSize = useScreenSize()
  return (
    <div className={`${isHomePage ? 'w-full' : 'w-[66%]'} cursor-pointer`}>
      <div className="flex flex-col gap-1">
        <div className="flex justify-between gap-1 text-wrap">
          <h3
            className={`text-[18px] mobile:text-[16px] ${isHomePage ? 'w-[80%]' : 'w-[70%]'} font-ns_bold text-wrap line-clamp-5`}
          >
            {newsData && decodeHTMLEntities(newsData?.title)}
          </h3>
          {isHomePage && (
            <div className="absolute top-[5px] right-[5px] flex gap-4 mobile:hidden">
              {isHomePage && newsData?.is_highlighted && (
                <p
                  className={`!cursor-default flex items-center gap-1.5 rounded-[3px] px-1.5 py-0.5 ${isHomePage ? '' : 'pr-5'} text-white bg-hightlight_bg text-[14px] `}
                  onClick={(e) => {
                    e.preventDefault()
                    e.stopPropagation()
                  }}
                >
                  <Image
                    loading="lazy"
                    width={11}
                    height={11}
                    className="w-[11.7px] h-[11.7px] object-cover"
                    src="/assets/Icons/highlightIcon.svg"
                    alt=""
                  />
                  {t('Trending')}
                </p>
              )}
              <div
                onClick={(e) => {
                  e.preventDefault()
                  e.stopPropagation()
                }}
              >
                <ShareSocials
                  sharAbleLink={`${window.location.origin}/${params?.lng}/news/news-detail/${newsData?.id}`}
                  shareText={newsData?.title?.slice(0, 50)}
                  shareBg={false}
                  share_id={newsData?.id}
                />
              </div>
            </div>
          )}
        </div>
        <div className="flex gap-2 items-center">
          <CountryTag name={newsData?.origin} size="xs" />
          <div
            className={`${isHomePage ? 'text-xs' : 'text-sm'} text-greyish_brown mobile:text-[12px]`}
          >
            {newsData?.published_at
              ? newsData?.published_at
              : t('date not available')}
          </div>
        </div>
        <div className=" ">
          <div
            className={`${isHomePage ? 'text-base' : 'text-sm'}  py-3 text-wrap mobile:text-[14px] mobile:pt-0`}
          >
            <p className={`text-[#666] flex  `}>
              {screenSize?.width >= 768
                ? decodeHTMLEntities(sanitizeHtml(newsData?.caption))?.slice(
                    0,
                    120
                  )
                : decodeHTMLEntities(sanitizeHtml(newsData?.caption))
                    ?.split(' ')
                    .slice(0, 20)
                    .join(' ')}

              {decodeHTMLEntities(sanitizeHtml(newsData?.caption))?.length ??
              0 > 1
                ? '...'
                : ''}
            </p>
          </div>
          <div className={`${isHomePage ? 'hidden' : ''} mobile:block`}>
            <div className="absolute right-[10px] bottom-[10px] flex gap-[10px]">
              {newsData?.is_highlighted && (
                <p
                  className={`flex items-center gap-1.5 rounded-[3px] px-1.5 py-0.5 ${isHomePage ? '' : 'pr-5'} text-white bg-hightlight_bg text-xs`}
                >
                  <Image
                    loading="lazy"
                    width={11}
                    height={11}
                    className="w-fit h-fit object-cover"
                    src="/assets/Icons/highlightIcon.svg"
                    alt=""
                  />
                  {t('Trending')}
                </p>
              )}
              <div
                onClick={(e) => {
                  e.preventDefault()
                  e.stopPropagation()
                }}
              >
                <ShareSocials
                  sharAbleLink={`${window.location.origin}/${params?.lng}/news/news-detail/${newsData?.id}`}
                  shareText={newsData?.title?.slice(0, 50)}
                  shareBg={false}
                  share_id={newsData?.id}
                />
              </div>
            </div>
          </div>
        </div>
        <div className="text-sm text-secondary cursor-pointer font-ns_semibold">
          {t('Read More')} &gt;&gt;
        </div>
      </div>
    </div>
  )
}

export default NewsResultCardContent
